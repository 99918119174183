<template>
  <PageLayout>
    <NuxtPage />
  </PageLayout>
</template>

<script setup lang="ts">

const { radio: playerRadio } = usePlayerState()

playerRadio.value = 'boa'
</script>
